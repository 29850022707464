<template>
  <div class="w-100 px-2 mt-5">
    <v-container fluid class="pt-0 pb-15 d-flex flex-column">
      <span class="white--text font-30 app-medium-font ml-5 mb-3">{{
        $t("agencies")
      }}</span>
      <v-data-table
        :headers="headers"
        :items="agencies"
        :items-per-page="15"
        :loading="loading"
        @click:row="onSelectClient"
        class="elevation-0"
      >
        <template v-slot:[`item.image`]="{ item }">
          <div
            @click="goToProfile(item)"
            style="width: 200px;"
            class="d-flex flex-column align-center mt-3"
          >
            <v-img
              :src="
                item.bgImage
                  ? item.bgImage
                  : require('@/assets/background0.png')
              "
              width="200"
              style="flex-grow: 1"
              :aspect-ratio="
                $vuetify.breakpoint.smAndDown ? 414 / 180 : 1654 / 455
              "
              class="rounded-lg rounded-b-0 position-absolute"
            />
            <editable-avatar
              :image="item.thumbnail ? item.thumbnail : item.image"
              :editable="false"
              :size="50"
              class="mt-5"
            />
          </div>
          <div class="url-button app-bold-font" @click="goToProfile(item)">
            {{ item.name }}
          </div>
          <div>
            {{ formatPhoneNumber(item.phone) }}
            <v-tooltip bottom v-if="item.phone_verified">
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="14" class="ml-1" v-bind="attrs" v-on="on"
                  >mdi-check-circle</v-icon
                >
              </template>
              Phone verified
            </v-tooltip>
          </div>
          <div>
            {{ item.email }}
            <v-tooltip bottom v-if="item.email_verified">
              <template v-slot:activator="{ on, attrs }">
                <v-icon size="14" class="ml-1" v-bind="attrs" v-on="on"
                  >mdi-check-circle</v-icon
                >
              </template>
              Email verified
            </v-tooltip>
          </div>
          <div class="d-flex flex-wrap mb-3">
            <site-icon
              :name="icon"
              type="Cause"
              v-for="(icon, key) in item.causes"
              :key="key"
              :size="30"
              class="mr-3 mt-2"
            />
          </div>
        </template>
        <template v-slot:[`item.info`]="{ item }">
          <div class="app-bold-font font-12">Causes</div>
          <div>
            <v-chip
              v-for="(icon, key) in item.causes"
              :key="key"
              class="mr-1 mt-2"
              x-small
              >{{ icon }}</v-chip
            >
          </div>
          <div class="app-bold-font font-12 mt-2">Address</div>
          <div>
            <div>
              {{ item.address
              }}{{ item.address2 ? ", " + item.address2 + "" : "" }}
            </div>
            <div>{{ item.city }}, {{ item.state }} {{ item.zip }}</div>
          </div>
          <div class="app-bold-font font-12 mt-2">Followers</div>
          <div class="d-flex flex-column mt-1">
            <span>Fanned by {{ item.followed_by.length }} volunteers</span>
            <span>Followed by {{ item.followers.length }} agencies</span>
          </div>
        </template>
        <template v-slot:[`item.about_me`]="{ item }">
          <div>
            <div class="app-bold-font font-12">Who we are</div>
            <div class="text-block">{{ item.who_we_are }}</div>
            <div class="app-bold-font font-12 mt-2">What we do</div>
            <div class="text-block">{{ item.what_we_do }}</div>
          </div>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          <div class="app-bold-font font-12">Created at</div>
          <div>{{ formatDate(item.createdAt) }}</div>
          <div class="app-bold-font font-12 mt-2">Status</div>
          <div>{{ item.status }}</div>
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onUpdateAgencyStatus(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18">{{
                    item.status == "Available" ? "mdi-close" : "mdi-check"
                  }}</v-icon>
                </v-btn>
              </template>
              {{ item.status == "Available" ? "Reject" : "Approve" }}
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onEditAgency(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              Edit
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  style="border-radius: 1px; border-color: #0A5B8A; border-style: solid; border-radius: 36px;"
                  small
                  class="ml-2"
                  @click.stop="onDeleteAgency(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon color="#0A5B8A" style="margin-top: 2px;" size="18"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              Delete
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
      <confirm-delete-dialog
        :deleteDialog="deleteDialog"
        :onClose="onCloseConfirmDelete"
        :onConfirm="onConfirmDelete"
      />
    </v-container>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import EditableAvatar from "@/components/EditableAvatar.vue";
import dateFormat from "dateformat";
import ConfirmDeleteDialog from "@/components/common/ConfirmDeleteDialog.vue";
import SiteIcon from "@/components/common/SiteIcon.vue";
export default {
  components: {
    EditableAvatar,
    ConfirmDeleteDialog,
    SiteIcon
  },
  data() {
    return {
      loading: false,
      headers: [
        { text: "", value: "image" },
        { text: "Info", value: "info" },
        { text: "About", value: "about_me" },
        { text: "", value: "createdAt" },
        { text: "Actions", value: "actions" }
      ],
      deleteDialog: false,
      selected: null,
      expanded: [],
      editDialog: false
    };
  },
  methods: {
    ...mapActions("agency", {
      fetchAllAgencies: "fetchAllAgencies",
      updateAgencyStatus: "updateAgencyStatus",
      deleteAgency: "deleteAgency"
    }),
    onSelectClient(item) {
      console.log(item);
    },
    formatDate(date) {
      return dateFormat(this.convertTZ(date, this.timezone), "mm/dd/yyyy");
    },
    formatPhoneNumber(str) {
      let cleaned = ("" + str).replace(/\D/g, "");
      let match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
      if (match) {
        let intlCode = match[1] ? "+1 " : "";
        return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join(
          ""
        );
      }
      return str;
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York"
          }
        )
      );
    },
    goToProfile(item) {
      this.$router.push({
        name: "agency-profile-details",
        params: { id: item._id }
      });
    },
    onUpdateAgencyStatus(item) {
      this.loading = true;
      this.updateAgencyStatus({
        _id: item._id,
        status:
          item.status == "Pending" || item.status == "Rejected"
            ? "Available"
            : "Rejected"
      })
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    onEditAgency(item) {
      this.selected = item;
      // this.editDialog = true;
      this.$router.push({
        name: "edit-agency",
        params: { id: item._id }
      });
    },
    onCloseEditDialog() {
      this.editDialog = false;
    },
    onDeleteAgency(item) {
      this.selected = item;
      this.deleteDialog = true;
    },
    onCloseConfirmDelete() {
      this.deleteDialog = false;
    },
    onConfirmDelete() {
      this.deleteDialog = false;
      this.loading = true;
      this.deleteAgency({ _id: this.selected._id })
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  computed: {
    ...mapGetters({
      agencies: "agency/getAllAgencies",
      profile: "auth/getProfile",
      profileType: "auth/getType",
      timezone: "usersetting/getTimeZone"
    })
  },
  mounted() {
    console.log(this.profileType);
    if (this.profileType != "Admin") {
      this.$router.push({ name: "home" });
    }
    this.loading = true;
    this.fetchAllAgencies()
      .then(() => {
        this.loading = false;
      })
      .catch(error => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  }
};
</script>
<style scoped>
.agency-subtitle {
  color: #472583;
  font-size: 14px;
}
</style>
